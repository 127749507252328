<template>
    <div class="companyManagement box" id="ygh-content-box">
        <s-table ref="table" :columns="columns" :data="loadData" :alert="false" :rowKey="(record) => record.id">
            <template #operator>
                <a-form layout="inline">
                    <a-form-item>
                        <a-input style="width: 150px" placeholder="请输入企业名称" v-model="queryParam.searchValue">
                        </a-input>
                    </a-form-item>
                    <a-form-item label="企业类型">
                        <a-select placeholder="请选择" style="width: 150px" v-model="queryParam.corpType" allowClear>
                            <a-select-option v-for="i in corpTypeOptiosn" :key="i.value" :value="i.value">
                                {{ i.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="使用状态">
                        <a-select placeholder="请选择" style="width: 150px" v-model="queryParam.deleteFlag" allowClear>
                            <a-select-option v-for="i in deleteFlagOptions" :key="i.value" :value="i.value">
                                {{ i.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="授权状态">
                        <a-select placeholder="请选择" style="width: 150px" v-model="queryParam.openFlag" allowClear>
                            <a-select-option value="Y"> 服务阶段 </a-select-option>
                            <a-select-option value="N"> 试用阶段 </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="到期时间">
                        <a-range-picker
                            style="width: 220px"
                            valueFormat="YYYY-MM-DD"
                            v-model="queryParam.expireTime"
                            allowClear
                        />
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" @click="$refs.table.refresh()"> 查询 </a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" @click="reset"> 重置 </a-button>
                    </a-form-item>
                </a-form>
            </template>
            <span slot="corpSquareLogoUrl" slot-scope="text">
                <img class="avt" :src="text" alt="" />
            </span>
            <span slot="corpType" slot-scope="text">
                {{ foramtCorpType(text) }}
            </span>
            <span slot="deleteFlag" slot-scope="text" :style="{ color: text === 'N' ? 'red' : 'green' }">
                {{ foramtDeleteFlag(text) }}
            </span>
            <span slot="amount" slot-scope="text" style="color: #3b9afa">
                {{ text }}
            </span>
            <span slot="createTime" slot-scope="text">
                {{ formatTime(text) }}
            </span>
            <span slot="expireTime" slot-scope="text, record">
                <div class="cursor-df">
                    <!-- {{ formatTime(text) }} -->
                    <a-date-picker
                        :disabled-date="disabledDateHandler"
                        :allowClear="false"
                        @ok="(time) => updateExpireTime(time, record)"
                        show-time
                        :default-value="moment(text, dateFormat)"
                    />
                </div>
            </span>
            <span slot="deleteTime" slot-scope="text">
                {{ formatTime(text) }}
            </span>
            <span slot="openFlag" slot-scope="text">
                {{ text == 'Y' ? '服务阶段' : '试用阶段' }}
            </span>
            <span slot="action" slot-scope="text, record">
                <a-button @click="setMess(record)" style="padding-left: 0" type="link">开通</a-button>
                <a-button @click="more(record)" style="padding-left: 0" type="link">更多</a-button>
            </span>
        </s-table>
        <setMess @refresh="$refs.table.refresh(true)" ref="setMess"></setMess>
        <showMoreMess ref="showMoreMess" />
    </div>
</template>
<script>
import { wxCorpPage, wxOvertime } from '@/api/modular/fileStream/documentManage'
import { STable } from '@/components'
import moment from 'moment'
import setMess from './components/setMess.vue'
import showMoreMess from './components/showMoreMess.vue'
import { corpTypeOptiosn, defaultColumns, deleteFlagOptions } from './config'
export default {
    components: {
        STable,
        setMess,
        showMoreMess,
    },
    data() {
        return {
            dateFormat: 'YYYY-MM-DD HH:mm:ss',
            columns: defaultColumns,
            corpTypeOptiosn,
            deleteFlagOptions,
            queryParam: {
                // corpType: '',
                // deleteFlag: '',
                searchValue: '',
                expireTime: [],
            },
            func: wxCorpPage,
            loadData: (parameter) => {
                const { corpType, deleteFlag, searchValue, expireTime } = this.queryParam
                const params = {
                    corpType,
                    deleteFlag,
                    searchValue,
                    searchBeginTime: expireTime ? expireTime[0] : '',
                    searchEndTime: expireTime ? expireTime[1] : '',
                }
                return this.func(Object.assign(parameter, params)).then((res) => res.data)
            },
            selectedRowKeys: [],
        }
    },
    methods: {
        moment,
        // 处理日期
        disabledDateHandler(current) {
            return current < moment().subtract(1, 'days').endOf('day')
        },
        onSearch() {},
        setMess({ id }) {
            this.$refs.setMess.open(id)
        },
        more({ permanentCode, corpId, deleteTime, openTime }) {
            this.$refs.showMoreMess.open({ permanentCode, corpId, deleteTime, openTime })
        },
        reset() {
            this.queryParam = {
                corpType: '',
                deleteFlag: '',
                searchValue: '',
                expireTime: [],
            }
            this.$refs.table.refresh()
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
        },
        foramtCorpType(text) {
            return this.corpTypeOptiosn.find((j) => j.value === text)?.label
        },
        formatTime(time) {
            if (!time) return ''
            const timeStr = time.replace(/-/g, '/')
            return new Date(timeStr).toLocaleString().replace(/\//g, '-')
        },
        foramtDeleteFlag(text) {
            return this.deleteFlagOptions.find((j) => j.value === text)?.label
        },
        // 修改服务时间
        updateExpireTime(time, { id }) {
            let params = {
                id,
                expireTime: time.format(this.dateFormat),
            }
            wxOvertime({ ...params }).then((res) => {
                const { success } = res
                if (success) {
                    this.$message.success('修改成功')
                    this.$refs.table.refresh(true)
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.avt {
    height: 50px;
    object-fit: cover;
}

.box {
    background: #ffffff;
    border-radius: 4px;
    padding: 33px 39px;
}

.companyManagement {
    margin: auto !important;
    overflow-y: auto;

    .operatorContainer {
        justify-content: space-between;
    }
}
</style>
