<template>
  <a-modal
    title="开通"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
      <a-form-item label="使用人数">
        <a-input-number :min="0" v-decorator="['amount']" style="width: 100%;" />
      </a-form-item>
      <a-form-item label="到期时间">
        <a-date-picker
          valueFormat="YYYY-MM-DD"
          style="width: 100%;"
          v-decorator="['expireTime']" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import {
  wxCorpEdit
} from '@/api/modular/fileStream/documentManage'
export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: 'setMess' }),
      visible: false,
      messId: ''
    }
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          wxCorpEdit({
            amount: values.amount,
            expireTime: `${values.expireTime} 00:00:00`,
            id: this.messId
          })
          .then(res => {
            if (res.code === 200) {
              this.$message.success('编辑成功')
              this.$emit('refresh')
              this.visible = false
            }
          })
        }
      })
    },
    handleCancel() {
      this.visible = false
    },
    open(id) {
      this.visible = true
      this.messId = id
    }
  }
}
</script>
