export const defaultColumns = [
  {
    title: '企业名称',
    dataIndex: 'corpName'
  },
  {
    title: '企业类型',
    dataIndex: 'corpType',
    scopedSlots: { customRender: 'corpType' }
  },
  {
    title: '企业头像',
    dataIndex: 'corpSquareLogoUrl',
    scopedSlots: { customRender: 'corpSquareLogoUrl' }
  },
  {
    title: '企业规模',
    dataIndex: 'corpScale'
  },
  {
    title: '所属行业',
    dataIndex: 'corpIndustry'
  },
  {
    title: '应用编号',
    dataIndex: 'agentId'
  },
  {
    title: '登录次数',
    dataIndex: 'loginNum',
    scopedSlots: { customRender: 'amount' }
  },
  {
    title: '使用人数',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' }
  },
  {
    title: '使用状态',
    dataIndex: 'deleteFlag',
    scopedSlots: { customRender: 'deleteFlag' }
  },
  {
    title: '安装时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    title: '到期时间',
    dataIndex: 'expireTime',
    scopedSlots: { customRender: 'expireTime' }
  },
  // {
  //   title: '最近一次卸载时间',
  //   dataIndex: 'deleteTime',
  //   scopedSlots: { customRender: 'deleteTime' }
  // },
  {
    title: '授权状态',
    dataIndex: 'openFlag',
    scopedSlots: { customRender: 'openFlag' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed:'right',
    scopedSlots: { customRender: 'action' }
  }
]
export const corpTypeOptiosn = [
  {
    label: '认证号',
    value: 'verified'
  },
  {
    label: '注册号',
    value: 'unverified'
  }
]
export const deleteFlagOptions = [
  {
    label: '正常',
    value: 'Y'
  },
  {
    label: '已卸载',
    value: 'N'
  }
]
